import React from 'react';
import { MapContext } from './MapView';
import PropTypes from 'prop-types';

/**
 * Render a marker on the map at a given position.
 *
 * Usage:
 * ```jsx
 * <MapView>
 *   <Marker
 *      lat={1.299} lng={103.838}
 *      iconUrl={'http://icon-url.png'}>
 *     {...}
 *   </Marker>
 * </MapView>
 * ```
 */
class Marker extends React.Component {
  componentWillUnmount() {
    // Remove marker on component unmount
    this.marker && this.marker.setMap(null);
  }

  drawMarker(map, lat, lng) {
    const google = window.google;

    if (!this.marker) {
      const markerIcon = {
        url: this.props.iconUrl,
        /**
         *  Instead of writing `scaledSize: new google.maps.Size(47, 35)`
         *  url: 'http://mock-icon-url',
            scaledSize: {
              g: undefined,
              h: undefined,
              height: 47,
              width: 35
            }
         */
        scaledSize: {
          height: 47,
          width: 35
        }
      };
      this.marker = new google.maps.Marker({
        // eslint-disable-line
        position: { lat: Number(lat), lng: Number(lng) },
        map: map,
        icon: markerIcon,
        /**
         * Instead of writing - animation: google.maps.Animation.DROP
         * {
         *  $m: 0,
         *  BOUNCE: 1
         *  DROP: 2,
         *  bn: 3
         * }
         */
        animation: 2,
        visible: true
      });
      this.marker.addListener('click', this.onMarkerClick);
    }
    this.marker.setPosition({ lat, lng });
    return this.marker;
  }

  onMarkerClick = () => {
    if (typeof this.props.onClick === 'function') {
      return this.props.onClick();
    }
  };

  render() {
    return (
      <MapContext.Consumer>
        {mapContext => {
          const { map } = mapContext;
          const { lat, lng } = this.props;

          // render marker
          const marker = map && this.drawMarker(map, lat, lng);
          return (
            <MarkerContext.Provider value={{ marker }}>
              {this.props.children}
            </MarkerContext.Provider>
          );
        }}
      </MapContext.Consumer>
    );
  }
}

Marker.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  iconUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func
};

export default Marker;

export const MarkerContext = React.createContext({
  marker: null
});
