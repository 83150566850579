import styled from 'styled-components';
import { Theme } from '@lux/components-gomo';

export const H1 = styled.h1`
  margin: 0 0 4px 0;
  font-size: ${Theme.fonts.sizes.regular};
`;

export const P = styled.p`
  margin: 0;
  font-size: ${Theme.fonts.sizes.small};
  line-height: 1.2;
`;

export const RepositionOnTopOfMarker = styled.div`
  position: absolute;
  bottom: -45px;
  transform: translate(-50%, -100%);
`;

const LocationBubble = styled.div`
  position: relative;
  background: ${Theme.colours.gomo_ocean_blue};
  color: #fff;
  font-family: ${Theme.fonts.families.Poppins};
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 4px 10px -4px #000;
  display: inline-block;

  &::after {
    content: '';
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid ${Theme.colours.gomo_ocean_blue};
  }
`;

LocationBubble.H1 = H1;
LocationBubble.P = P;

export default LocationBubble;
