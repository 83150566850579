/**
 * MapView UI component
 * Render a google map and mark the pop stations.
 * Clicking on a pop-station shows a info bubble.
 */

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Marker from './Marker';
import PopupOverlay from './PopupOverlay';

/**
 * Render full width container with a google map instance rendered on it.
 *
 * An reference to the created instance of the google.maps.Maps Class
 * is pass down as context props (via `MapContext`) to the children.
 * The reference can be access using context consumer api.
 *
 * ```jsx
 * <MapContext.Consumer>
 *   {(context) => {
 *      // Access map using `context.map` }
 *   }
 * </MapContext.Consumer>
 * ```
 * @see React context api https://reactjs.org/docs/context.html#contextconsumer
 *
 *
 * Usage:
 *
 * ```jsx
 * <MapView
 *   apiKey={""}
 *   center={{ lat: 1.3521, lng: 103.8198 }}
 *   zoom={12}
 * >
 *  <ChildComponent /> // This component has access to
 * </MapView>
 * ```
 *
 * P.S. The component injects the google maps script in the head tag.
 */
class MapView extends React.Component {
  state = {
    mapInitialized: false
  };

  // google map instance
  map = null;

  /* Map mount DOM element */
  mapContainerMountRef = React.createRef();

  /* Unique hash to avoid name collisions with other window variables */
  gMapsLoadCallbackFnName = Math.random()
    .toString(36)
    .substring(7);

  componentDidMount = async () => {
    return injectGoogleMapsScript(
      this.props.apiKey,
      this.gMapsLoadCallbackFnName
    ).then(this.initMaps);
  };

  componentDidUpdate = prevProps => {
    if (!this.state.mapInitialized || !this.map) return;

    /* update map center */
    if (
      JSON.stringify(this.props.center) !== JSON.stringify(prevProps.center)
    ) {
      const { lat, lng } = this.props.center;
      this.map.setCenter({ lat, lng });
    }

    /* update map zoom */
    if (this.props.zoom !== prevProps.zoom) {
      const { zoom } = this.props;
      this.map.setZoom(zoom);
    }
  };

  initMaps = google => {
    const mapOptions = {
      zoom: this.props.zoom,
      center: this.props.center
    };

    this.map =
      google.maps &&
      google.maps.Map &&
      new google.maps.Map(this.mapContainerMountRef, mapOptions);

    // Set zoom changed event listener
    this.map.addListener('zoom_changed', event => {
      this.sendZoomValue(this.map.getZoom(event));
    });

    this.setState({ mapInitialized: true });
  };

  render() {
    return (
      <MapContext.Provider value={{ map: this.map }}>
        <MapContainer ref={ref => (this.mapContainerMountRef = ref)}>
          {this.props.children}
        </MapContainer>
      </MapContext.Provider>
    );
  }

  sendZoomValue = zoomValue => {
    this.props.onZoomCallback(zoomValue);
  };
}

MapView.propTypes = {
  apiKey: PropTypes.string.isRequired,
  zoom: PropTypes.number.isRequired,
  center: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired
  }).isRequired
};

MapView.Marker = Marker;
MapView.Popup = PopupOverlay;

export default MapView;

function injectGoogleMapsScript(apiKey, onloadCallbackFnName) {
  const script = document.createElement('script');
  return new Promise((resolve, reject) => {
    script.async = true;
    script.defer = true;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=${onloadCallbackFnName}`;
    script.type = 'text/javascript';

    // Setup on google maps ready callback
    window[onloadCallbackFnName] = () => {
      delete window[onloadCallbackFnName];
      window.google ? resolve(window.google) : reject();
    };

    if (document.head) {
      document.head.appendChild(script);
    }
  });
}

export const MapContext = React.createContext({
  map: null // Map api
});

const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
`;
