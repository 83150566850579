import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Column, Row, Theme } from '@lux/components-gomo';
import { noop } from '@lux/helpers';

import { PAGINATE_COUNT } from '../../constants/app_configuration';
import { DAY, MONTH } from '../../constants/gregorian_calendar_terms.json';
import { TextItem, Anchor } from '../../components/tracked-components';

const Content = styled.div`
  margin: ${Theme.spacing.small} 0 0 0;
`;

const TextItemContainer = styled.div`
  display: inline-block;
  width: 30%;
  margin-right: ${Theme.spacing.small};
  margin-bottom: ${Theme.spacing.small};
  &:nth-child(3n) {
    margin-right: 0;
  }
`;

const DesktopTextItemContainer = styled(Column)`
  padding-right: ${Theme.spacing.small};
  padding-top: ${Theme.spacing.small};
`;

const StyledAnchorContainer = styled.div`
  margin-top: ${Theme.spacing.small};
  margin-bottom: ${Theme.spacing.medium};
`;

const StyledText = styled.div`
  display: block;
`;

const getDateObject = dateStr => {
  const [day, month, year] = dateStr.split('-');
  const monthIndex = MONTH.MEDIUM.findIndex(m => m === month);
  return new Date(Date.UTC(year, monthIndex, day));
};

const formatDate = str => {
  return str
    .split('-')
    .splice(0, 2, '')
    .join(' ');
};

const DateCollection = props => {
  const {
    data,
    page,
    selectedDate,
    selectedTime,
    onDateChange,
    onTimeChange,
    onShowLessClick,
    onShowMoreClick,
    hideTime
  } = props;

  const times = !hideTime ? data[selectedDate] && data[selectedDate].times : [];

  const showMoreOrLessMap = {
    more: { clickHandler: onShowMoreClick, anchorText: 'Show more' },
    less: { clickHandler: onShowLessClick, anchorText: 'Show less' }
  };

  const endIndex =
    page < Object.keys(data).length ? page : Object.keys(data).length;

  const determinePager = page < Object.keys(data).length ? 'more' : 'less';

  const anchorClickHandler = showMoreOrLessMap[determinePager].clickHandler;
  const anchorText = showMoreOrLessMap[determinePager].anchorText;

  return (
    <Fragment>
      <Row>
        <Column sm={12} md={12} lg={12}>
          <Row>
            {Object.keys(data)
              .slice(0, endIndex)
              .map(date => {
                const dateObj = getDateObject(date);
                const day = DAY.MEDIUM[dateObj.getDay()];
                const noYRdate = formatDate(date);

                return (
                  <DesktopTextItemContainer key={date} sm={4} md={4} lg={4}>
                    <TextItem
                      id={date}
                      onClick={() => onDateChange(date)}
                      selected={selectedDate === date}
                      block
                    >
                      <StyledText data-testid="getDay">{day}</StyledText>
                      <StyledText
                        data-testid={`testSelectDeliveryDate-${date}`}
                      >
                        {noYRdate}
                      </StyledText>
                    </TextItem>
                  </DesktopTextItemContainer>
                );
              })}
          </Row>
          {Object.keys(data).length > PAGINATE_COUNT && (
            <StyledAnchorContainer>
              <Anchor data-testId="showmoreless" onClick={anchorClickHandler}>
                {anchorText}
              </Anchor>
            </StyledAnchorContainer>
          )}
        </Column>
        {!hideTime && times && (
          <Fragment>
            <Column sm={12} md={false} lg={false}>
              <Content>
                {times.map(timeObj => {
                  return (
                    <TextItemContainer key={`${selectedDate}-${timeObj.time}`}>
                      <TextItem
                        data-testid="testSelectDeliveryDate"
                        id={`${selectedDate}-${timeObj.time}`}
                        onClick={() => onTimeChange(timeObj.time)}
                        selected={selectedTime === timeObj.time}
                        block
                      >
                        <StyledText>{timeObj.time}</StyledText>
                      </TextItem>
                    </TextItemContainer>
                  );
                })}
              </Content>
            </Column>
            <Column sm={false} md={12} lg={12}>
              <Row>
                {times.map(timeObj => {
                  return (
                    <DesktopTextItemContainer
                      key={`${selectedDate}-${timeObj.time}`}
                      sm={4}
                      md={4}
                      lg={4}
                    >
                      <TextItem
                        id={`${selectedDate}-${timeObj.time}`}
                        onClick={() => onTimeChange(timeObj.time)}
                        selected={selectedTime === timeObj.time}
                        block
                      >
                        <StyledText
                          data-testid={`testSelectDeliveryTime-${timeObj.time}`}
                        >
                          {timeObj.time}
                        </StyledText>
                      </TextItem>
                    </DesktopTextItemContainer>
                  );
                })}
              </Row>
            </Column>
          </Fragment>
        )}
      </Row>
    </Fragment>
  );
};

DateCollection.defaultProps = {
  data: {},
  selectedDate: '',
  selectedTime: '',
  page: 0,
  onDateChange: noop,
  onTimeChange: noop,
  onShowLessClick: noop,
  onShowMoreClick: noop,
  hideTime: false
};

DateCollection.propTypes = {
  /** Date object with date strings as its dynamic keys */
  data: PropTypes.object,
  /** Selected date */
  selectedDate: PropTypes.string,
  /** Selected time */
  selectedTime: PropTypes.string,
  /** Date change event handler */
  onDateChange: PropTypes.func,
  /** Time change event handler */
  onTimeChange: PropTypes.func,
  /** Show more click handler */
  onShowLessClick: PropTypes.func,
  /** Show less click handler */
  onShowMoreClick: PropTypes.func,
  /** Should hide time picker*/
  hideTime: PropTypes.bool
};

export default DateCollection;
