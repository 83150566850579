import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Heading, TextItem, Column, Row, Theme } from '@lux/components-gomo';

import withApiFailureModal from '../withApiFailureModal';
import CourierOption from './CourierOption';
import withRedirect from '../withRedirect';
import SelfCollection from './SelfCollection';
import { SLOT_TYPES } from './../../constants/transaction_codes.json';

import actions from '../../actions';
import burgerImageSrc from '../../assets/images/burger-phone.png';
import { BurgerImage } from '../../components/Layout';

const TextItemContainer = styled(Column)`
  margin: 0 ${Theme.spacing.small} ${Theme.spacing.small} 0;
`;

//Contains another container because more modes of delivery will be added later.
//Each mode of delivery will then be their own container.
export class InputDeliveryDetails extends Component {
  state = {
    hideSelfCollection:
      typeof window !== 'undefined' && window.disableSelfCollection !== null
        ? window.disableSelfCollection
        : false
  };

  componentDidMount() {
    // reserve for future if PO require reset again ( ͡👁️ ͜ʖ ͡👁️)
    // this.resetUserDetailsPage();
    if (this.props.selectedNumber) {
      window.onbeforeunload = function() {
        return 'You will lose any unsaved changes. Are you sure you want to leave this page?';
      };
    }
  }

  handleDeliveryOptions = isSelfCollect => {
    const deliveryMode = isSelfCollect
      ? SLOT_TYPES.POPSTATION
      : SLOT_TYPES.HOMEDELIVERY;
    const address = {
      blk: '',
      blockNumber: '',
      street: '',
      unitNumbers: [],
      postalCode: '',
      buildClass: '',
      floorNumber: '',
      unitNumber: ''
    };
    const deliveryDetails = {
      address: address,
      date: '',
      time: '',
      code: '',
      slotType: deliveryMode,
      pMode: this.props.pMode
    };

    if (isSelfCollect !== this.props.isSelfCollection) {
      //Reset delivery dates
      this.props.setDeliveryDates([], deliveryMode, this.props.pMode);
      //Reset popstation
      this.props.setSelectedStation({});
      //Clear delivery details
      this.props.setDeliveryDetails(deliveryDetails);

      //Clear retrieved delivery address
      this.props.retrieveDeliveryAddressClear();

      //Set DeliveryMode on switch
      this.props.setSelfCollect(isSelfCollect);
    }
  };

  render() {
    const { isSelfCollection, isPortIn } = this.props;
    const { hideSelfCollection } = this.state;

    return (
      <Fragment>
        <Heading level={4} secondary>
          Your SIM card needs you!
        </Heading>
        <Heading level={2}>How would you like your SIM card delivered?</Heading>
        <Column sm={12} lg={5}>
          <Row>
            <TextItemContainer sm={6} md={6} lg={6}>
              <TextItem
                block
                onClick={() =>
                  isPortIn === 'portIn'
                    ? this.handleDeliveryOptions(false)
                    : false
                }
                selected={!isSelfCollection}
                data-testId="HomeDeliveryBtn"
              >
                <p>Delivery</p>
              </TextItem>
            </TextItemContainer>
            {isPortIn === 'portIn' && !hideSelfCollection && (
              <TextItemContainer id="SelfCollectionBtn">
                <TextItem
                  block
                  onClick={() => this.handleDeliveryOptions(true)}
                  selected={isSelfCollection}
                  data-testId="SelfCollectionBtn"
                >
                  <p>Self Collection</p>
                </TextItem>
              </TextItemContainer>
            )}
          </Row>
        </Column>
        {isSelfCollection ? (
          <SelfCollection />
        ) : (
          <Row>
            <Column sm={12} lg={5}>
              <CourierOption />
            </Column>
            <Column sm={0} lg={7}>
              <BurgerImage src={burgerImageSrc} />
            </Column>
          </Row>
        )}
      </Fragment>
    );
  }
}

InputDeliveryDetails.defaultProps = {
  resetUserDetails: () => null,
  resetUploadImage: () => null,
  retrieveAddressClear: () => null
};

export const mapStateToProps = state => {
  return {
    selectedNumber: state.selectedNumber.number,
    isPortIn: state.selectedNumber.mode,
    isSelfCollection: state.selfCollection.isSelfCollection,
    allPopstations: state.selfCollection.allPopstations,
    pMode: state.deliveryDates.pMode
  };
};

/* istanbul ignore next */
export const mapDispatchToProps = dispatch => {
  const {
    setSelfCollect,
    setDeliveryDates,
    setSelectedStation,
    setDeliveryDetails,
    retrieveDeliveryAddressClear,
    resetUserDetails,
    resetUploadImage,
    retrieveAddressClear
  } = actions;

  return {
    setSelfCollect: isSelfCollect => {
      dispatch(setSelfCollect(isSelfCollect));
    },
    setDeliveryDates: (dates, slotType, pMode) => {
      dispatch(setDeliveryDates(dates, slotType, pMode));
    },
    setSelectedStation: station => {
      dispatch(setSelectedStation(station));
    },
    setDeliveryDetails: deliveryDetails => {
      dispatch(setDeliveryDetails(deliveryDetails));
    },
    retrieveDeliveryAddressClear: () => {
      dispatch(retrieveDeliveryAddressClear());
    },
    resetUserDetails: () => dispatch(resetUserDetails()),
    resetUploadImage: () => dispatch(resetUploadImage()),
    retrieveAddressClear: () => dispatch(retrieveAddressClear())
  };
};

const withRedirectHoc = withRedirect(InputDeliveryDetails);
const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRedirectHoc);

export default withApiFailureModal(connected);
