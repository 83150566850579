import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Theme, Row, Column, Heading } from '@lux/components-gomo';
import { noop, remCalc } from '@lux/helpers';

import { getDistanceFromLatLonInKm } from '../../helpers/geometry';

import MapView, { Marker } from '../MapView';
import ListDrawer from '../ListDrawer';
import LocationBubble, { RepositionOnTopOfMarker } from './LocationBubble';

import popupSelected from '../../assets/images/popup_selected.png';
import popupDeselected from '../../assets/images/popup_deselected.png';

import ITEM_VIEWS from '../../constants/item_views.json';
import { SINGAPORE } from '../../constants/geography.json';

const GOOGLE_API_KEY = process.env.GATSBY_GOOGLE_API_KEY;
const { MAP_FILTER, REGION_COORDINATES } = SINGAPORE;

const SCMapView = styled(MapView)`
  position: absolute;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 1;
`;

const ListWrapper = styled.div`
  width: 100%;
  ${Theme.media.md`
    background-color: #f0f0f1;
    position: relative;
    height: ${remCalc(635)};
    z-index: 2;
    -webkit-flex-basis: 41.7%;
    -ms-flex-preferred-size: 41.7%;
    flex-basis: 41.7%;
    max-width: 41.7%;
  `}
`;

const MapContainer = styled(Column)`
  min-height: ${remCalc(276)};
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  ${Theme.media.md`
    border: 1px solid ${Theme.colours.gomo_grey_be};
    height: ${remCalc(635)};
  `}
`;

const MapWrapper = styled.div`
  width: 100%;
  height: ${remCalc(238)};
  position: absolute;
  right: 0;
  top: ${remCalc(48)};
  display: ${p => (p.show ? 'block' : 'none')};
  ${Theme.media.md`
    width: 58.3%;
    height: ${remCalc(635)};
    display: block;
    top: 0;
  `}
`;

const StyledBubbleHeading = styled(Heading)`
  color: ${Theme.colours.iris_white};
`;

export class PopStationMap extends Component {
  state = {
    filter: ITEM_VIEWS.MAP,
    center: {
      lat: parseFloat(
        this.props.preselected.Latitude
          ? this.props.preselected.Latitude
          : REGION_COORDINATES[MAP_FILTER.ALL].lat
      ),
      lng: parseFloat(
        this.props.preselected.Longitude
          ? this.props.preselected.Longitude
          : REGION_COORDINATES[MAP_FILTER.ALL].lng
      ),
      KioskId: this.props.preselected.KioskId
        ? this.props.preselected.KioskId
        : null
    },
    activeStation: this.props.preselected ? this.props.preselected : {},
    updateKey: 0,
    zoomValue: 12,
    initialMapLoadFlag: 1
  };

  setCenter = selectedStation => {
    let mapKey = this.state.updateKey;
    this.setState({
      center: {
        lat: parseFloat(selectedStation.Latitude),
        lng: parseFloat(selectedStation.Longitude),
        KioskId: selectedStation.KioskId
      },
      activeStation: selectedStation,
      updateKey: `m${mapKey++}`,
      zoomValue: 17,
      initialMapLoadFlag: 0
    });
    this.props.selectedStation(selectedStation);
  };

  setFilter = view => {
    this.setState({
      filter: view,
      isMapView: view === ITEM_VIEWS.MAP || false
    });
  };

  setCurrentZoomValue = zoomValue => {
    this.setState({
      zoomValue: zoomValue
    });
  };

  componentDidUpdate(prevProps) {
    let mapKey = this.state.updateKey;
    const { zoneFilter, activePostcodeDetails } = this.props;
    if (prevProps.zoneFilter !== zoneFilter && REGION_COORDINATES[zoneFilter]) {
      this.setState({
        center: {
          lat: parseFloat(REGION_COORDINATES[zoneFilter].lat),
          lng: parseFloat(REGION_COORDINATES[zoneFilter].lng),
          KioskId: null
        },
        updateKey: `m${mapKey++}`,
        zoomValue: 12,
        initialMapLoadFlag: 0,
        activeStation: {}
      });
    }
    if (prevProps.activePostcodeDetails !== activePostcodeDetails) {
      this.setState({
        initialMapLoadFlag: 1,
        activeStation: {}
      });
    }
  }

  sortPopStationListByDistance = (activeLatLng, popstations) => {
    const resultWithProximity = popstations.map(pop => ({
      ...pop,
      proximity: getDistanceFromLatLonInKm(
        activeLatLng.lat,
        activeLatLng.lng,
        parseFloat(pop.Latitude),
        parseFloat(pop.Longitude)
      )
    }));
    return this.sortByKey(resultWithProximity, 'proximity');
  };

  sortByKey(array, key) {
    return array.sort(function(a, b) {
      const x = a[key];
      const y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  render() {
    const {
      popstations,
      zoneFilter,
      activePostcodeDetails,
      isPostCode
    } = this.props;
    const {
      center,
      filter,
      activeStation,
      isMapView,
      updateKey,
      initialMapLoadFlag
    } = this.state;
    let latitude = parseFloat(center.lat),
      longitude = parseFloat(center.lng),
      zoomValue = 15;

    if (isPostCode && initialMapLoadFlag === 1) {
      latitude = parseFloat(activePostcodeDetails.lat);
      longitude = parseFloat(activePostcodeDetails.lng);
    } else {
      zoomValue = this.state.zoomValue;
    }

    //Filter popstations
    const popstationFiltered =
      zoneFilter !== MAP_FILTER.ALL
        ? popstations.filter(station => station.Region === zoneFilter)
        : popstations;

    const shouldSortPopStation = popstation => {
      return isPostCode
        ? this.sortPopStationListByDistance(
            {
              lat: latitude,
              lng: longitude
            },
            popstation
          )
        : popstation;
    };

    //Show all stations for south(0 result)
    const popstationList =
      popstationFiltered.length > 0
        ? shouldSortPopStation(popstationFiltered)
        : shouldSortPopStation(popstations);

    return (
      <Fragment>
        <MapContainer lg={12}>
          <Row>
            <ListWrapper>
              <ListDrawer
                popstations={popstationList}
                isMobile={false}
                selectedStation={e => this.setCenter(e)}
                stationId={center.KioskId}
                activeFilter={e => this.setFilter(e)}
              />
            </ListWrapper>
          </Row>
          <MapWrapper
            show={filter === ITEM_VIEWS.MAP && isMapView}
            data-testid={'mapwrapper'}
          >
            <SCMapView
              center={{
                lat: latitude,
                lng: longitude
              }}
              zoom={
                this.state.initialMapLoadFlag === 1
                  ? zoomValue
                  : this.state.zoomValue
              }
              key={updateKey}
              apiKey={GOOGLE_API_KEY}
              onZoomCallback={this.setCurrentZoomValue}
            >
              {/* Add marker to active postal code */}
              {isPostCode && (
                <Marker
                  lat={parseFloat(activePostcodeDetails.lat)}
                  lng={parseFloat(activePostcodeDetails.lng)}
                  iconUrl={popupSelected}
                />
              )}

              {popstationList.map((station, i) => {
                return (
                  <Fragment key={i}>
                    <Marker
                      lat={
                        station.Latitude === activeStation.Latitude
                          ? parseFloat(activeStation.Latitude)
                          : parseFloat(station.Latitude)
                      }
                      lng={
                        station.Longitude === activeStation.Longitude
                          ? parseFloat(activeStation.Longitude)
                          : parseFloat(station.Longitude)
                      }
                      onClick={() => this.setCenter(station)}
                      key={
                        station.KioskId === activeStation.KioskId
                          ? updateKey
                          : i
                      }
                      iconUrl={
                        station.KioskId === activeStation.KioskId
                          ? popupSelected
                          : popupDeselected
                      }
                    >
                      {station.KioskId === activeStation.KioskId && (
                        <MapView.Popup>
                          <RepositionOnTopOfMarker>
                            <LocationBubble>
                              <StyledBubbleHeading level={5}>
                                {activeStation.POPStationName}
                              </StyledBubbleHeading>
                              <p>
                                {activeStation.HouseBlockNumber}{' '}
                                {activeStation.StreetName}, Singapore{' '}
                                {activeStation.ZipCode}{' '}
                              </p>
                              <p>{activeStation.Location}</p>
                            </LocationBubble>
                          </RepositionOnTopOfMarker>
                        </MapView.Popup>
                      )}
                    </Marker>
                  </Fragment>
                );
              })}
            </SCMapView>
          </MapWrapper>
        </MapContainer>
      </Fragment>
    );
  }
}

PopStationMap.defaultProps = {
  popstations: [],
  selectedStation: noop,
  zoneFilter: MAP_FILTER.ALL,
  preselected: {}
};

PopStationMap.propTypes = {
  /** List of Popstations */
  popstations: PropTypes.array,
  selectedStation: PropTypes.func,
  /** Zone filter */
  zoneFilter: PropTypes.string,
  /** Preselect popstation*/
  preselected: PropTypes.object,
  activePostcodeDetails: PropTypes.shape({
    code: PropTypes.number,
    lat: PropTypes.number,
    lng: PropTypes.number
  }),
  isPostCode: PropTypes.bool
};

export default PopStationMap;
