import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import {
  GenericModalContent,
  Heading,
  Label,
  Paragraph,
  Theme
} from '@lux/components-gomo';
import { noop } from '@lux/helpers';

import actions from '../../../actions';

import { checkFieldsInObject } from '../../../helpers/validation';
import { checkPostalCode } from '../../../helpers/address';
import { determineAvailableDates } from '../../../helpers/datetime';

import DateCollection from '../../../components/DateCollection';
import RetrievePostalCode from '../../../components/RetrievePostalCode';
import InputAddress from '../../../components/InputAddress';
import NavControls from '../../../components/NavControls';

import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../../selectors';
import { Modal } from '../../../components/tracked-components';

import { PAGINATE_COUNT } from '../../../constants/app_configuration';
import {
  GTM_DATALAYER_EVENTS,
  GTM_EVENT_ACTIONS,
  GTM_EVENT_CATEGORIES
} from '../../../constants/gtmDataLayerEvents';
import { CTA_TEXT } from '../../../constants/page_content.json';
import { getErrorPopupDataLayerVars } from '../../../helpers/datalayer';

const StyledHeading = styled(Heading)`
  color: ${Theme.colours.gomo_ocean_blue};
  margin: ${Theme.spacing.medium} 0 0 0;
`;

const StyledParagraph = styled(Paragraph)`
  margin: ${Theme.spacing.medium} 0;
`;

const retrieveAddressSelector = createLoadingSelector(['RETRIEVE_ADDRESS']);
const retrieveAddressErrorSelector = createErrorMessageSelector([
  'RETRIEVE_ADDRESS'
]);

const deliveryDatesError = createErrorMessageSelector(['GET_DELIVERY_DATES']);

export class CourierOption extends Component {
  state = {
    selectedDate: this.props.deliveryDetails.date,
    selectedTime: this.props.deliveryDetails.time,
    postalCode: this.props.deliveryDetails.address.postalCode,
    retrievedPostalCode: this.props.deliveryDetails.address.postalCode,
    floorNumber: this.props.deliveryDetails.address.floorNumber,
    unitNumber: this.props.deliveryDetails.address.unitNumber,
    errors: {},
    addressList: [],
    selectedAddressIdx: null
  };

  componentDidMount() {
    const { deliveryDetails, deliveryAddressList } = this.props;
    let selectedAddressIdx = null;
    //If user is navigating back from delivery details page, prepopulate the street address dropdown
    if (
      deliveryDetails &&
      deliveryDetails.address.street &&
      deliveryAddressList &&
      deliveryAddressList.length > 0
    ) {
      selectedAddressIdx = deliveryAddressList.findIndex(
        address =>
          address.blockNumber === deliveryDetails.address.blockNumber &&
          address.street === deliveryDetails.address.street
      );
    } else {
      const { serviceNumber, authToken, conType } = this.props;
      this.props.getDeliveryDates(serviceNumber, authToken, conType);
    }
    this.setState({
      selectedAddressIdx: selectedAddressIdx >= 0 ? selectedAddressIdx : null
    });
  }

  componentDidUpdate(prevProps) {
    // Set default delivery address to first one if there is only one address
    if (
      !prevProps.deliveryAddressList &&
      this.props.deliveryAddressList &&
      this.props.deliveryAddressList.length === 1
    ) {
      this.setState({
        selectedAddressIdx: 0
      });
    }

    if (
      prevProps.retrieveAddressLoading &&
      !this.props.retrieveAddressLoading
    ) {
      this.bottomView.scrollIntoView({ behavior: 'smooth' });
    }
  }

  isValidForm = () => {
    const fieldsToCheckFromProps = ['deliveryAddressList'];
    let fieldsToCheckFromState = ['selectedDate', 'selectedTime', 'postalCode'];

    const addressToRender = this.determineNewAddress();

    fieldsToCheckFromState = !addressToRender.unitNumbers
      ? fieldsToCheckFromState
      : [...fieldsToCheckFromState, 'floorNumber', 'unitNumber'];

    return (
      checkFieldsInObject(this.state, fieldsToCheckFromState) &&
      checkFieldsInObject(
        { deliveryAddressList: this.determineNewAddress() },
        fieldsToCheckFromProps
      )
    );
  };

  getAddressList = () => {
    let addressList = [];
    const { deliveryAddressList } = this.props;

    if (deliveryAddressList && deliveryAddressList.length > 0) {
      addressList = deliveryAddressList.map((address, idx) => {
        return {
          text: address.blockNumber + ' ' + address.street,
          value: idx
        };
      });
    }
    return addressList;
  };

  determineNewAddress = () => {
    let address = {};

    const {
      retrievedPostalCode,
      floorNumber,
      unitNumber,
      selectedAddressIdx
    } = this.state;

    const { deliveryAddressList, deliveryDetails } = this.props;

    if (deliveryAddressList && selectedAddressIdx !== null) {
      // user has retrieved addresss using postal code
      address = {
        blk: deliveryAddressList[selectedAddressIdx].blk,
        blockNumber: deliveryAddressList[selectedAddressIdx].blockNumber,
        street: deliveryAddressList[selectedAddressIdx].street,
        unitNumbers: deliveryAddressList[selectedAddressIdx].unitNumbers,
        buildClass: deliveryAddressList[selectedAddressIdx].buildClass,
        postalCode: retrievedPostalCode,
        floorNumber,
        unitNumber
      };
    } else {
      // user has submitted once and come back to the page,
      // so use submitted address if they haven't retrieved new one
      address = {
        blk: deliveryDetails.address.blk,
        blockNumber: deliveryDetails.address.blockNumber,
        street: deliveryDetails.address.street,
        unitNumbers: deliveryDetails.address.unitNumbers,
        postalCode: deliveryDetails.address.postalCode,
        buildClass: deliveryDetails.address.buildClass,
        floorNumber,
        unitNumber
      };
    }

    return address;
  };

  handleNextButtonClick = () => {
    if (this.isValidForm()) {
      const foundTimeObj = this.props.deliveryDates.data[
        this.state.selectedDate
      ].times.find(t => t.time === this.state.selectedTime);
      const details = {
        address: this.determineNewAddress(),
        date: this.state.selectedDate,
        time: this.state.selectedTime,
        code: foundTimeObj.code,
        slotType: this.props.deliveryDates.slotType,
        pMode: this.props.deliveryDates.pMode
      };
      this.props.setDeliveryDetails(details);

      navigate('/user-details/');
    }
  };

  handleRetrieveAddressClick = () => {
    const { postalCode } = this.state;
    const errors = { ...this.state.errors };
    const error = checkPostalCode(postalCode);
    if (!error) {
      this.props.retrieveAddress(
        postalCode,
        this.props.authToken,
        this.props.serviceNumber,
        'delivery'
      );
      delete errors['postalCode'];
    } else {
      errors.postalCode = error;
    }
    this.setState({
      errors,
      floorNumber: '',
      unitNumber: '',
      noUnitDetails: false,
      selectedAddressIdx: null,
      retrievedPostalCode: postalCode
    });
  };

  handleDateChange = date => {
    this.setState({ selectedDate: date, selectedTime: '' });
  };

  handleTimeChange = time => {
    this.setState({ selectedTime: time });
  };

  handleAddressSelectionChange = e => {
    this.setState({
      selectedAddressIdx: e.target.value ? parseInt(e.target.value) : null,
      floorNumber: '',
      unitNumber: ''
    });
  };

  handleDropdownChange = (e, key) => {
    let unitNumber = '';
    if (key === 'unitNumber') {
      unitNumber = e.target.value;
    }

    this.setState({ [key]: e.target.value, unitNumber });
  };

  handleTextChange = (e, key) => {
    this.setState({ [key]: e.target.value });
  };

  handleTextBlur = (e, key) => {
    const text = e.target.value;
    const errors = { ...this.state.errors };

    const checkHandlerMapping = { postalCode: checkPostalCode };

    const error = checkHandlerMapping[key](text);
    if (error !== '') {
      errors[key] = error;
    } else {
      delete errors[key];
    }

    this.setState({ errors });
  };

  handleModalClose = type => {
    const { allPopstations } = this.props;
    this.props.clearError(type);

    //User has already previously loaded popstation
    if (
      allPopstations &&
      allPopstations.length &&
      type === 'GET_DELIVERY_DATES'
    ) {
      //Set DeliveryMode back to popstation
      this.props.setSelfCollect(true);
    }
    this.props.retrieveAddressStatus(null);
  };

  handleSetDatesPage = page => {
    this.props.setDatesPage(page);
  };

  renderErrorMessage = ({
    header = 'OOPS',
    message = 'Failed to retrieve address'
  }) => {
    const errorModalDataLayerVars = getErrorPopupDataLayerVars({
      event: GTM_DATALAYER_EVENTS.DELIVERY_SCHEDULED,
      errorName: message,
      eventLabel: CTA_TEXT.OK_GOT_IT
    });

    return (
      <Modal
        onModalRender={noop}
        onModalClose={() => {
          this.handleModalClose('RETRIEVE_ADDRESS');
        }}
        renderProp={({ onModalClose }) => (
          <GenericModalContent
            theme="hybrid"
            headerText={header}
            paragraphText={message}
            buttonText={CTA_TEXT.OK_GOT_IT}
            onModalClose={onModalClose}
          />
        )}
        trackEvent={{
          modalRender: errorModalDataLayerVars.render,
          modalClose: errorModalDataLayerVars.buttonClick
        }}
      />
    );
  };

  render() {
    const {
      selectedDate,
      selectedTime,
      postalCode,
      selectedAddressIdx,
      floorNumber,
      unitNumber,
      errors
    } = this.state;

    const {
      deliveryDates,
      deliveryAddressList,
      retrieveAddressLoading,
      retrieveAddressError,
      delDatesPage,
      deliveryDatesError,
      addressStatus
    } = this.props;

    const hasSelectedDateAndTime = selectedDate && selectedTime;
    const formattedDeliveryAddressList = this.getAddressList();
    const addressToRender = this.determineNewAddress();
    const delDatesAndTime = determineAvailableDates(deliveryDates.data);

    const deliveryErrorDataLayerVars = getErrorPopupDataLayerVars({
      event: GTM_DATALAYER_EVENTS.DELIVERY_SCHEDULED,
      errorName: deliveryDatesError?.ERR,
      eventLabel: deliveryDatesError?.CTATEXT || CTA_TEXT.OK_GOT_IT
    });

    return (
      <Fragment>
        <StyledHeading level={4} secondary>
          Delivery via courier!
        </StyledHeading>
        <StyledParagraph>
          Sign up before 10:30 AM to enjoy same-day delivery (subject to
          availability). Please present your NRIC or authorize someone to
          receive your SIM card on your behalf.
        </StyledParagraph>
        <Label>Delivery date and time</Label>
        <DateCollection
          data={delDatesAndTime}
          selectedDate={selectedDate}
          selectedTime={selectedTime}
          page={delDatesPage}
          onDateChange={this.handleDateChange}
          onTimeChange={this.handleTimeChange}
          onShowLessClick={() =>
            this.handleSetDatesPage(parseInt(PAGINATE_COUNT))
          }
          onShowMoreClick={() =>
            this.handleSetDatesPage(parseInt(delDatesPage + PAGINATE_COUNT))
          }
        />
        {(hasSelectedDateAndTime || deliveryAddressList) && (
          <RetrievePostalCode
            addressLabel="Delivery postal code"
            postalCode={postalCode}
            error={errors['postalCode']}
            retrieveAddressLoading={retrieveAddressLoading}
            onTextBlur={this.handleTextBlur}
            onTextChange={this.handleTextChange}
            onRetrieveAddressClick={this.handleRetrieveAddressClick}
          />
        )}
        {(hasSelectedDateAndTime || deliveryAddressList) && (
          <InputAddress
            addressList={formattedDeliveryAddressList}
            selectedAddressIdx={selectedAddressIdx}
            address={addressToRender}
            floorNumber={floorNumber}
            unitNumber={unitNumber}
            errors={errors}
            onDropdownChange={this.handleDropdownChange}
            onAddressSelectionChange={this.handleAddressSelectionChange}
          />
        )}
        {(hasSelectedDateAndTime || deliveryAddressList) &&
          addressToRender.street && (
            <NavControls
              disableNextButton={!this.isValidForm()}
              onNextButtonClick={this.handleNextButtonClick}
              trackEventNextButton={{
                click: {
                  event: GTM_DATALAYER_EVENTS.DELIVERY_SCHEDULED,
                  eventCategory: GTM_EVENT_CATEGORIES.CONTENT_CLICK,
                  eventAction: GTM_EVENT_ACTIONS.BUTTON_CLICK,
                  eventLabel: CTA_TEXT.NEXT
                }
              }}
            />
          )}
        <div ref={el => (this.bottomView = el)} />
        {retrieveAddressError &&
          addressStatus &&
          this.renderErrorMessage({
            header: addressStatus.header,
            message: addressStatus.message
          })}
        {deliveryDatesError && (
          <Modal
            onModalClose={() => this.handleModalClose('GET_DELIVERY_DATES')}
            onModalRender={noop}
            renderProp={({ onModalClose }) => (
              <GenericModalContent
                theme="hybrid"
                headerText={deliveryDatesError.TITLE}
                paragraphText={deliveryDatesError.ERR}
                buttonText={deliveryDatesError.CTATEXT || 'OK, GOT IT'}
                onModalClose={onModalClose}
              />
            )}
            trackEvent={{
              modalRender: deliveryErrorDataLayerVars.render,
              modalClose: deliveryErrorDataLayerVars.buttonClick
            }}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    deliveryDates: state.deliveryDates,
    deliveryAddressList: state.retrievedAddress.deliveryAddressList,
    addressStatus: state.retrievedAddress.addressStatus,
    deliveryDetails: state.deliveryDetails,
    retrieveAddressLoading: retrieveAddressSelector(state),
    retrieveAddressError: retrieveAddressErrorSelector(state),
    serviceNumber: state.selectedNumber.number,
    authToken: state.auth.token,
    delDatesPage: state.deliveryDates.page,
    conType: state.selectedNumber.mode,
    allPopstations: state.selfCollection.allPopstations,
    deliveryDatesError: deliveryDatesError(state)
  };
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => {
  const {
    retrieveAddress,
    setDeliveryDetails,
    clearError,
    setDatesPage,
    retrieveAddressClear,
    getDeliveryDates,
    setSelfCollect,
    retrieveAddressStatus,
    pushEventToDataLayer
  } = actions;

  return {
    retrieveAddress: (postalCode, authCode, serviceNumber, addressType) =>
      dispatch(
        retrieveAddress(postalCode, authCode, serviceNumber, addressType)
      ),
    setDeliveryDetails: details => dispatch(setDeliveryDetails(details)),
    clearError: errorKey => dispatch(clearError(errorKey)),
    setDatesPage: page => dispatch(setDatesPage(page)),
    retrieveAddressClear: () => dispatch(retrieveAddressClear()),
    getDeliveryDates: (serviceNumber, token, conType) =>
      dispatch(getDeliveryDates(serviceNumber, token, conType)),
    setSelfCollect: isSelfCollect => dispatch(setSelfCollect(isSelfCollect)),
    retrieveAddressStatus: status => dispatch(retrieveAddressStatus(status)),
    pushEventToDataLayer: data => dispatch(pushEventToDataLayer(data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourierOption);
