import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { MapContext } from './MapView';
import { MarkerContext } from './Marker';

/**
 * Component renders its children as an overlay on the map and attached to
 * a given Marker.
 *
 * Example
 * ```jsx
 * <MapView>
 *   <PopupOverlay>
 *     <YourOverlayComponent />
 *   </PopupOverlay>
 * </MapView>
 * ```
 */
class PopupOverlay extends Component {
  /**
   * Overlay instance.
   * */
  overlay = null;

  /**
   * Dom element reference to the content rendered in the InfoWindow
   * */
  el = null;

  drawOverlayAtMarker(marker) {
    this.el = this.el || createOverlayElement();
    const OverlayView = createOverlayViewClass();
    this.overlay =
      this.overlay ||
      new OverlayView({
        position: marker.getPosition(),
        content: this.el
      });
  }

  showOverlayInMap(map) {
    this.overlay.setMap(map);
  }

  removeOverlayFromMap() {
    if (this.overlay) {
      this.overlay.setMap(null);
    }
  }

  componentWillUnmount() {
    this.removeOverlayFromMap();
    delete this.overlay;
  }

  render() {
    return (
      <MapContext.Consumer>
        {mapContext => (
          <MarkerContext.Consumer>
            {markerContext => {
              const map = mapContext.map,
                marker = markerContext.marker;
              if (map && marker) {
                this.drawOverlayAtMarker(marker);
                this.showOverlayInMap(map);
                return ReactDOM.createPortal(this.props.children, this.el);
              } else {
                return null;
              }
            }}
          </MarkerContext.Consumer>
        )}
      </MapContext.Consumer>
    );
  }
}

PopupOverlay.propTypes = {
  children: PropTypes.node
};

export default PopupOverlay;

function createOverlayElement() {
  const el = document.createElement('div');
  el.style.position = 'absolute';
  el.style.display = 'inline-block';
  el.style.width = '9999px';
  return el;
}

export const createOverlayViewClass = () => {
  class OverlayView extends window.google.maps.OverlayView {
    position = null;
    content = null;

    constructor(props) {
      super(props);
      props.position && (this.position = props.position);
      props.content && (this.content = props.content);
    }

    /** Called when the popup is added to the map. */
    onAdd = () => {
      this.getPanes().floatPane.appendChild(this.content);
    };

    /** Called when the popup is removed from the map. */
    onRemove = () => {
      if (this.content.parentElement) {
        /*asd*/
        this.content.parentElement.removeChild(this.content);
      }
    };

    /** Called each frame when the popup needs to draw itself. */
    draw = () => {
      const divPosition = this.getProjection().fromLatLngToDivPixel(
        this.position
      );
      //
      // // Hide the popup when it is far out of view.
      const display =
        Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
          ? 'inline-block'
          : 'none';

      if (display === 'inline-block') {
        this.content.style.left = divPosition.x + 'px';
        this.content.style.top = divPosition.y + 'px';
      }
      if (this.content.style.display !== display) {
        this.content.style.display = display;
      }
    };
  }

  return OverlayView;
};
