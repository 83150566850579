import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import {
  GenericModalContent,
  Heading,
  Theme,
  TextItem,
  Row,
  Column,
  Paragraph
} from '@lux/components-gomo';
import { noop } from '@lux/helpers';

import actions from '../../../actions';

import { isEmptyObj } from '../../../helpers/validation';
import { checkPostalCode } from '../../../helpers/address';
import { determineAvailableDates } from '../../../helpers/datetime';

import RetrievePostalCode from '../../../components/RetrievePostalCode';

import NavControls from '../../../components/NavControls';
import PopStationMap from '../../../components/PopStationMap';
import DateCollection from '../../../components/DateCollection';

import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../../selectors';
import { Modal } from '../../../components/tracked-components';

import { PAGINATE_COUNT } from '../../../constants/app_configuration';
import { SLOT_TYPES } from '../../../constants/transaction_codes.json';
import { SINGAPORE } from '../../../constants/geography.json';
import { CTA_TEXT } from '../../../constants/page_content.json';
import {
  GTM_DATALAYER_EVENTS,
  GTM_EVENT_ACTIONS,
  GTM_EVENT_CATEGORIES
} from '../../../constants/gtmDataLayerEvents';
import { getErrorPopupDataLayerVars } from '../../../helpers/datalayer';

const { MAP_FILTER } = SINGAPORE;

const StyledHeading = styled(Heading)`
  color: ${Theme.colours.gomo_ocean_blue};
  margin: ${Theme.spacing.medium} 0 0 0;
  text-transform: uppercase;
`;

const ZoneContainer = styled.div`
  margin: 0 ${Theme.spacing.small} ${Theme.spacing.small} 0;
  width: 31.3%;
  flex-grow: 1;
  &:nth-child(3) {
    margin: 0 0 ${Theme.spacing.small} 0;
  }
`;

const ToggleItem = styled.div`
  text-align: center;
  padding: 0;
  margin: ${Theme.spacing.xsmall} 0;
  font-family: ${Theme.fonts.families.Poppins};
  font-size: ${Theme.fonts.sizes.large};
  ${p =>
    p.selected
      ? `
      font-weight: ${Theme.fonts.weight.bold};
      color: ${Theme.colours.gomo_ocean_blue};
    `
      : `
      font-weight: ${Theme.fonts.weight.light};
      color: ${Theme.colours.gomo_grey_be};
    `}
  &:hover {
    cursor: pointer;
  }
`;

const ToggleItemContainer = styled(Column)`
  border-bottom: 2px solid
    ${p =>
      p.active ? Theme.colours.gomo_ocean_blue : Theme.colours.gomo_grey_be};
  padding: ${Theme.spacing.small} 0;
  margin: ${Theme.spacing.small} 0;
  &:hover {
    cursor: pointer;
  }
`;

const ZoneRow = styled.div`
  display: flex;
`;

const getByPostcodeLoading = createLoadingSelector([
  'GET_POPSTATIONS_BYPOSTCODE'
]);
const getByPostcodeError = createErrorMessageSelector([
  'GET_POPSTATIONS_BYPOSTCODE'
]);
const getPopstationsLoading = createLoadingSelector(['GET_POPSTATIONS']);

const getPopstationsErrorSelector = createErrorMessageSelector([
  'GET_POPSTATIONS'
]);

const deliveryDatesError = createErrorMessageSelector(['GET_DELIVERY_DATES']);

export class SelfCollection extends Component {
  state = {
    errors: {},
    selectedDate: this.props.deliveryDetails.date
      ? this.props.deliveryDetails.date
      : '',
    selectedTime: this.props.deliveryDetails.time
      ? this.props.deliveryDetails.time
      : ''
  };

  componentDidMount() {
    const { conType, allPopstations } = this.props;
    if (!allPopstations.length) {
      return this.props.fetchPopStations(SLOT_TYPES.POPSTATION, conType);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      getByPostcodeLoading,
      getPopstationsLoading,
      getDeliveryDatesLoading
    } = this.props;

    //On popstation search by postcode
    if (
      prevProps.getByPostcodeLoading !== getByPostcodeLoading &&
      this.popstation
    ) {
      this.popstation.scrollIntoView({ behavior: 'smooth' });
    }

    //On Popstation loaded
    if (
      prevProps.getPopstationsLoading !== getPopstationsLoading &&
      this.popstationByPostcode
    ) {
      this.popstationByPostcode.scrollIntoView({ behavior: 'smooth' });
    }

    //On Delivery dates loaded
    if (
      prevProps.getDeliveryDatesLoading !== getDeliveryDatesLoading &&
      this.deliveryTimes
    ) {
      this.deliveryTimes.scrollIntoView({ behavior: 'smooth' });
    }
  }

  handleRetrieveAddressClick = () => {
    const { postalCode } = this.state;
    const errors = { ...this.state.errors };
    const error = checkPostalCode(postalCode);
    const { allPopstations, authToken, serviceNumber } = this.props;

    if (!error) {
      this.props.setPopstationByPostCode({
        postalCode: postalCode,
        allPopstations: allPopstations,
        authToken: authToken,
        serviceNumber: serviceNumber
      });
      delete errors['postalCode'];
    } else {
      errors.postalCode = error;
    }
    this.setState({
      errors
    });
  };

  handleTextChange = (e, key) => {
    this.setState({ [key]: e.target.value });
  };

  handleTextBlur = (e, key) => {
    const text = e.target.value;
    const errors = { ...this.state.errors };

    const checkHandlerMapping = { postalCode: checkPostalCode };

    const error = checkHandlerMapping[key](text);
    if (error !== '') {
      errors[key] = error;
    } else {
      delete errors[key];
    }

    this.setState({ errors });
  };

  handleFilters = filterBy => {
    const { deliveryDates } = this.props;
    const errors = { ...this.state.errors };
    this.setState({
      activeFilter: filterBy,
      postalCode: '',
      errors: {
        postalCode: ''
      },
      selectedDate: '',
      selectedTime: '',
      deliveryCode: ''
    });

    if (errors.postalCode) {
      delete errors['postalCode'];
    }

    this.props.setSearchOption(filterBy);
    this.props.setSelectedStation({});
    this.props.setDeliveryDates({}, SLOT_TYPES.POPSTATION, deliveryDates.pMode);
  };

  handleSelectedStation = station => {
    const {
      serviceNumber,
      authToken,
      conType,
      selectedPopstation
    } = this.props;

    if (station === selectedPopstation) return;

    const outletId = station.KioskId;
    this.props.setDatesPage(PAGINATE_COUNT);
    this.props.setSelectedStation(station);
    this.props.showDeliveryDates({
      serviceNumber,
      token: authToken,
      conType: conType,
      outletCode: outletId
    });

    //Reset date on change of popstation
    this.setState({ selectedDate: '', selectedTime: '', deliveryCode: '' });
  };

  handleDateChange = date => {
    const { deliveryDates } = this.props;
    const time = deliveryDates.data[date];
    this.setState({
      selectedDate: date,
      selectedTime: time.times[0].time,
      deliveryCode: time.times[0].code
    });
  };

  handleSetDatesPage = page => {
    this.props.setDatesPage(page);
  };

  isValidForm = () => {
    return this.state.deliveryCode &&
      this.state.selectedDate &&
      this.props.selectedPopstation
      ? true
      : false;
  };

  handleModalClose = errorKey => {
    this.props.clearError(errorKey);

    if (errorKey === 'GET_POPSTATIONS') {
      this.props.setSelfCollect(false);
    } else if (errorKey === 'GET_POPSTATIONS_BYPOSTCODE') {
      //Custom error screen interactions for search by postcode
      if (this.props.getByPostcodeError.TYPE === 'NO_RESULT') {
        this.handleFilters(false);
      }
    }
  };

  handleNextButtonClick = () => {
    const { selectedPopstation } = this.props;

    const stationAddress = {
      blk: '',
      blockNumber: selectedPopstation.HouseBlockNumber,
      street: selectedPopstation.StreetName,
      unitNumbers: '',
      postalCode: selectedPopstation.ZipCode,
      buildClass: '',
      floorNumber: '',
      unitNumber: selectedPopstation.UnitNumber
    };

    const details = {
      address: stationAddress,
      date: this.state.selectedDate,
      time: this.state.selectedTime,
      code: this.state.deliveryCode,
      slotType: this.props.deliveryDates.slotType,
      pMode: this.props.deliveryDates.pMode
    };
    this.props.setDeliveryDetails(details);
    this.props.setSearchOption(false);

    navigate('/user-details/');
  };

  render() {
    const { selectedDate, postalCode, errors, selectedTime } = this.state;

    const {
      retrieveAddressLoading,
      isSearchByPostal,
      activeFilter,
      setSelfCollectFilter,
      activePostcode,
      showMap,
      allPopstations,
      selectedPopstation,
      deliveryDates,
      delDatesPage,
      getPopstationsError,
      getByPostcodeError,
      activePopstations,
      deliveryDatesError
    } = this.props;

    const delDates = deliveryDates
      ? determineAvailableDates(deliveryDates.data)
      : {};

    const getByPostCodeErrorDataLayerVars = getErrorPopupDataLayerVars({
      event: GTM_DATALAYER_EVENTS.DELIVERY_SCHEDULED,
      errorName: getByPostcodeError?.ERR,
      eventLabel: getByPostcodeError?.CTATEXT
    });

    const getPopstationsErrorDataLayerVars = getErrorPopupDataLayerVars({
      event: GTM_DATALAYER_EVENTS.DELIVERY_SCHEDULED,
      errorName: getPopstationsError?.ERR,
      eventLabel: getPopstationsError?.CTATEXT
    });

    const deliveryDatesErrorDataLayerVars = getErrorPopupDataLayerVars({
      event: GTM_DATALAYER_EVENTS.DELIVERY_SCHEDULED,
      errorName: deliveryDatesError?.ERR,
      eventLabel: deliveryDatesError?.CTATEXT || CTA_TEXT.OK_GOT_IT
    });

    return (
      <Fragment>
        {!!allPopstations.length && (
          <>
            <div ref={el => (this.popstation = el)} />
            <StyledHeading level={4} secondary>
              Popstation Location
            </StyledHeading>
            <Row>
              <Column sm={12} lg={5}>
                <Row>
                  <ToggleItemContainer active={!isSearchByPostal}>
                    <ToggleItem
                      block
                      onClick={() => this.handleFilters(false)}
                      selected={!isSearchByPostal}
                      data-testid="ZoneFilter"
                    >
                      Zone
                    </ToggleItem>
                  </ToggleItemContainer>
                  <ToggleItemContainer active={isSearchByPostal}>
                    <ToggleItem
                      block
                      onClick={() => this.handleFilters(true)}
                      selected={isSearchByPostal}
                      data-testid="PostcodeFilter"
                    >
                      Postal Code
                    </ToggleItem>
                  </ToggleItemContainer>
                </Row>
              </Column>
            </Row>
            {isSearchByPostal ? (
              <>
                <RetrievePostalCode
                  addressLabel="Delivery postal code"
                  postalCode={postalCode}
                  error={errors['postalCode']}
                  retrieveAddressLoading={retrieveAddressLoading}
                  onTextBlur={this.handleTextBlur}
                  onTextChange={this.handleTextChange}
                  onRetrieveAddressClick={this.handleRetrieveAddressClick}
                />
                <div ref={el => (this.popstationByPostcode = el)} />
                {activePostcode && (
                  <PopStationMap
                    isPostCode={isSearchByPostal}
                    activePostcodeDetails={activePostcode}
                    showMap={showMap}
                    popstations={activePopstations}
                    selectedStation={this.handleSelectedStation}
                  />
                )}
              </>
            ) : (
              <>
                <Row>
                  <Column sm={12} lg={5}>
                    <ZoneRow>
                      <ZoneContainer>
                        <TextItem
                          data-testid="btn-select-zone-all"
                          block
                          selected={activeFilter === MAP_FILTER.ALL}
                          onClick={() => setSelfCollectFilter(MAP_FILTER.ALL)}
                        >
                          All
                        </TextItem>
                      </ZoneContainer>
                      <ZoneContainer>
                        <TextItem
                          data-testid="btn-select-zone-central"
                          block
                          selected={activeFilter === MAP_FILTER.CENTRAL}
                          onClick={() =>
                            setSelfCollectFilter(MAP_FILTER.CENTRAL)
                          }
                        >
                          Central
                        </TextItem>
                      </ZoneContainer>
                      <ZoneContainer>
                        <TextItem
                          data-testid="btn-select-zone-north"
                          block
                          selected={activeFilter === MAP_FILTER.NORTH}
                          onClick={() => setSelfCollectFilter(MAP_FILTER.NORTH)}
                        >
                          North
                        </TextItem>
                      </ZoneContainer>
                    </ZoneRow>
                    <ZoneRow>
                      <ZoneContainer>
                        <TextItem
                          data-testid="btn-select-zone-northeast"
                          block
                          selected={activeFilter === MAP_FILTER.NORTHEAST}
                          onClick={() =>
                            setSelfCollectFilter(MAP_FILTER.NORTHEAST)
                          }
                        >
                          North East
                        </TextItem>
                      </ZoneContainer>
                      <ZoneContainer>
                        <TextItem
                          data-testid="btn-select-zone-east"
                          block
                          selected={activeFilter === MAP_FILTER.EAST}
                          onClick={() => setSelfCollectFilter(MAP_FILTER.EAST)}
                        >
                          East
                        </TextItem>
                      </ZoneContainer>
                      <ZoneContainer>
                        <TextItem
                          data-testid="btn-select-zone-west"
                          block
                          selected={activeFilter === MAP_FILTER.WEST}
                          onClick={() => setSelfCollectFilter(MAP_FILTER.WEST)}
                        >
                          West
                        </TextItem>
                      </ZoneContainer>
                    </ZoneRow>
                  </Column>
                </Row>
                <Row>
                  <Column lg={12}>
                    <PopStationMap
                      isPostCode={isSearchByPostal}
                      showMap={showMap}
                      popstations={allPopstations}
                      selectedStation={this.handleSelectedStation}
                      zoneFilter={activeFilter}
                      preselected={selectedPopstation}
                    />
                  </Column>
                </Row>
              </>
            )}
            <Row>
              <Column sm={12} lg={5}>
                <div ref={el => (this.deliveryTimes = el)} />
                {!isEmptyObj(delDates) && (
                  <>
                    <StyledHeading level={4} secondary>
                      Select Collection Dates
                    </StyledHeading>
                    <DateCollection
                      data={delDates}
                      selectedDate={selectedDate}
                      selectedTime={selectedTime}
                      page={delDatesPage}
                      onDateChange={this.handleDateChange}
                      onShowLessClick={() =>
                        this.handleSetDatesPage(parseInt(PAGINATE_COUNT))
                      }
                      onShowMoreClick={() =>
                        this.handleSetDatesPage(
                          parseInt(delDatesPage + PAGINATE_COUNT)
                        )
                      }
                      hideTime={true}
                    />
                    {this.state.selectedDate && (
                      <Paragraph>
                        A guide and collection PIN will be sent to you via SMS
                        and email when your SIM card is ready for pick-up.
                      </Paragraph>
                    )}
                  </>
                )}
              </Column>
            </Row>
            <Row>
              <Column sm={12} lg={5}>
                <NavControls
                  disableNextButton={!this.isValidForm()}
                  onNextButtonClick={() => this.handleNextButtonClick()}
                  trackEventNextButton={{
                    click: {
                      event: GTM_DATALAYER_EVENTS.SELF_COLLECTION,
                      eventCategory: GTM_EVENT_CATEGORIES.CONTENT_CLICK,
                      eventAction: GTM_EVENT_ACTIONS.BUTTON_CLICK,
                      eventLabel: CTA_TEXT.NEXT
                    }
                  }}
                />
              </Column>
            </Row>
          </>
        )}
        {getByPostcodeError && (
          <Modal
            onModalClose={() =>
              this.handleModalClose('GET_POPSTATIONS_BYPOSTCODE')
            }
            onModalRender={noop}
            renderProp={({ onModalClose }) => (
              <GenericModalContent
                theme="hybrid"
                headerText={getByPostcodeError.TITLE}
                paragraphText={getByPostcodeError.ERR}
                buttonText={getByPostcodeError.CTATEXT}
                onModalClose={onModalClose}
              />
            )}
            trackEvent={{
              modalRender: getByPostCodeErrorDataLayerVars.render,
              modalClose: getByPostCodeErrorDataLayerVars.buttonClick
            }}
            data-testid="postcodeErrorModal"
          />
        )}
        {getPopstationsError && (
          <Modal
            onModalClose={() => this.handleModalClose('GET_POPSTATIONS')}
            onModalRender={noop}
            renderProp={({ onModalClose }) => (
              <GenericModalContent
                theme="hybrid"
                headerText={getPopstationsError.TITLE}
                paragraphText={getPopstationsError.ERR}
                buttonText={getPopstationsError.CTATEXT}
                onModalClose={onModalClose}
              />
            )}
            trackEvent={{
              modalRender: getPopstationsErrorDataLayerVars.render,
              modalClose: getPopstationsErrorDataLayerVars.buttonClick
            }}
            data-testid="popstationErrorModal"
          />
        )}
        {deliveryDatesError && (
          <Modal
            onModalClose={() => this.handleModalClose('GET_DELIVERY_DATES')}
            onModalRender={noop}
            renderProp={({ onModalClose }) => (
              <GenericModalContent
                theme="hybrid"
                headerText={deliveryDatesError.TITLE}
                paragraphText={deliveryDatesError.ERR}
                buttonText={deliveryDatesError.CTATEXT || 'OK, GOT IT'}
                onModalClose={onModalClose}
              />
            )}
            trackEvent={{
              modalRender: deliveryDatesErrorDataLayerVars.render,
              modalClose: deliveryDatesErrorDataLayerVars.buttonClick
            }}
          />
        )}
      </Fragment>
    );
  }
}

export const mapStateToProps = state => {
  return {
    serviceNumber: state.selectedNumber.number,
    conType: state.selectedNumber.mode,
    authToken: state.auth.token,
    isSelfCollection: state.selfCollection.isSelfCollection,
    isSearchByPostal: state.selfCollection.isSearchByPostal,
    activeFilter: state.selfCollection.selfCollectFilter,
    activePostcode: state.selfCollection.activePostcode,
    selectedPopstation: state.selfCollection.selectedPopstation,
    showMap: state.selfCollection.showMap,
    allPopstations: state.selfCollection.allPopstations,
    deliveryDates: state.deliveryDates,
    delDatesPage: state.deliveryDates.page,
    deliveryDetails: state.deliveryDetails,
    getPopstationsError: getPopstationsErrorSelector(state),
    getPopstationsLoading: getPopstationsLoading(state),
    getByPostcodeLoading: getByPostcodeLoading(state),
    getByPostcodeError: getByPostcodeError(state),
    activePopstations: state.selfCollection.activePopstations,
    deliveryDatesError: deliveryDatesError(state)
  };
};

/* istanbul ignore next */
export const mapDispatchToProps = dispatch => {
  const {
    setSearchOption,
    setSelfCollectFilter,
    getPopstationByPostCode,
    setPopstationByPostCode,
    fetchPopStations,
    showDeliveryDates,
    setDatesPage,
    setSelectedStation,
    setDeliveryDetails,
    setDeliveryDates,
    clearError,
    setSelfCollect,
    pushEventToDataLayer
  } = actions;

  return {
    setSearchOption: searchOption => dispatch(setSearchOption(searchOption)),
    setSelfCollectFilter: collectFilter =>
      dispatch(setSelfCollectFilter(collectFilter)),
    setPopstationByPostCode: popstations =>
      dispatch(setPopstationByPostCode(popstations)),
    getPopstationByPostCode: postcode =>
      dispatch(getPopstationByPostCode(postcode)),
    showDeliveryDates: params => dispatch(showDeliveryDates(params)),
    setSelectedStation: station => dispatch(setSelectedStation(station)),
    fetchPopStations: (slot, con) => dispatch(fetchPopStations(slot, con)),
    setDatesPage: page => dispatch(setDatesPage(page)),
    setDeliveryDetails: details => dispatch(setDeliveryDetails(details)),
    setDeliveryDates: (dates, slotType, pmode) =>
      dispatch(setDeliveryDates(dates, slotType, pmode)),
    clearError: errorKey => dispatch(clearError(errorKey)),
    setSelfCollect: isSelfCollect => dispatch(setSelfCollect(isSelfCollect)),
    pushEventToDataLayer: data => dispatch(pushEventToDataLayer(data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelfCollection);
