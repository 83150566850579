import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  TextItem,
  Theme,
  Heading,
  Column,
  Anchor,
  Row
} from '@lux/components-gomo';
import { noop, remCalc } from '@lux/helpers';
import MapViewIcon from '../../assets/svgs/map-view.svg';
import ListViewIcon from '../../assets/svgs/listing-view.svg';
import ITEM_VIEWS from '../../constants/item_views.json';

const ListWrapper = styled.div`
  background-color: #f0f0f1;
  position: relative;
  overflow: hidden;
  height: ${remCalc(635)};
  padding: 0;
  ${Theme.media.md`
    padding: 0 ${Theme.spacing.small};
  `}
`;

const ListContainer = styled.div`
  padding: ${remCalc(35)} 0;
  height: 100vh;
`;

const TextItemContainer = styled.div`
  margin-bottom: ${Theme.spacing.xsmall};
  padding: ${Theme.spacing.xsmall} 0;
  display: ${p => (p.show ? 'block' : 'none')};
`;

const TextItemDesc = styled.div`
  text-align: left;
  padding: 0 ${Theme.spacing.small};
  color: ${p =>
    p.selected ? Theme.colours.iris_white : Theme.colours.pupil_black};
`;

const StyledHeading = styled(Heading)`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
  color: ${p =>
    p.selected ? Theme.colours.iris_white : Theme.colours.pupil_black};
  ${Theme.media.md`
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
  `}
`;

export const ArrowUp = styled.div`
  padding: ${Theme.spacing.xsmall} 0;
  position: absolute;
  left: 0;
  top: ${remCalc(10)};
  width: 100%;
  text-align: center;
  background-color: #f0f0f1;
`;
export const ArrowDown = styled.div`
  padding: ${Theme.spacing.xsmall} 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: #f0f0f1;
`;

const ToggleRight = styled.div`
  background: ${p =>
    p.active ? Theme.colours.gomo_ocean_blue : Theme.colours.gomo_grey_be};
  padding: ${Theme.spacing.xsmall};
  width: ${remCalc(40)};
  height: ${remCalc(40)};
  text-align: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 3px 3px 0;
  margin-top: 5px;
`;

const ToggleLeft = styled.div`
  background: ${p =>
    p.active ? Theme.colours.gomo_ocean_blue : Theme.colours.gomo_grey_be};
  padding: ${Theme.spacing.xsmall};
  width: ${remCalc(40)};
  height: ${remCalc(40)};
  text-align: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px 0 0 3px;
  margin-top: 5px;
`;

const Controls = styled.div`
  padding: 0;
  display: flex;
  justify-content: flex-end;
`;

const MobileOnly = styled.div`
  ${Theme.media.md`
    display: none;
  `}
`;

const PageUp = styled.div`
  border: solid ${Theme.colours.gomo_ocean_blue};
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
`;

const PageDown = styled.div`
  border: solid ${Theme.colours.gomo_ocean_blue};
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-bottom: ${remCalc(10)};
`;

const StyledP = styled.p`
  ${Theme.media.md`
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
  `}
`;

class ListDrawer extends Component {
  state = {
    increment: 5,
    selectedStation: {},
    page: 1,
    view: ITEM_VIEWS.LIST
  };

  componentDidMount() {
    if (this.props.stationId) {
      this.findStation(this.props.stationId);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.stationId !== this.props.stationId) {
      this.findStation(this.props.stationId);
    }

    // Check if popstation count was changed
    if (prevProps.popstations.length !== this.props.popstations.length) {
      // Reset increment
      this.setState({
        increment: 5
      });
    }
  }

  selectedStation = station => {
    this.setState({
      selectedStation: station,
      stationId: station.KioskId
    });
    this.findStation(station.KioskId);
    this.props.selectedStation(station);
  };

  paginateItem = operator => {
    const max = this.props.popstations.length;
    let inc = this.state.increment;

    //Arrow down
    if (operator === 'add') {
      inc = inc < max ? inc + 5 : max;
      //Arrow up
    } else if (operator === 'sub') {
      inc = inc > 0 ? inc - 5 : 0;
    }

    this.setState({
      increment: inc
    });
  };

  findStation = stationId => {
    const { popstations } = this.props;
    const stationIdx = popstations.findIndex(
      station => station.KioskId === stationId
    );

    //Closest greater integer divisible by 5
    this.setState({
      increment: stationIdx + 5 - (stationIdx % 5)
    });
  };

  setFilter = filter => {
    this.setState({
      view: filter
    });
    this.props.activeFilter(filter);
  };

  render() {
    const { increment, view } = this.state;
    const { popstations, active, stationId } = this.props;
    return (
      <>
        <MobileOnly>
          <Column xs={12} lg={false} md={false}>
            <Row>
              <Column>
                <Controls>
                  <ToggleLeft
                    onClick={() => this.setFilter(ITEM_VIEWS.LIST)}
                    active={view === ITEM_VIEWS.LIST}
                    data-testid="ListFilter"
                  >
                    <ListViewIcon
                      fill={
                        view === ITEM_VIEWS.LIST
                          ? Theme.colours.gomo_grey_be
                          : Theme.colours.gomo_ocean_blue
                      }
                      size="16"
                    />
                  </ToggleLeft>
                  <ToggleRight
                    onClick={() => this.setFilter(ITEM_VIEWS.MAP)}
                    active={view === ITEM_VIEWS.MAP}
                    data-testid="MapFilter"
                  >
                    <MapViewIcon
                      fill={
                        view === ITEM_VIEWS.MAP
                          ? Theme.colours.gomo_grey_be
                          : Theme.colours.gomo_ocean_blue
                      }
                      size="16"
                    />
                  </ToggleRight>
                </Controls>
              </Column>
            </Row>
            {view === ITEM_VIEWS.LIST &&
              popstations.map((popstation, i) => {
                return (
                  <TextItemContainer key={i} show={increment > i}>
                    <TextItem
                      block={true}
                      {...this.props}
                      onClick={() => this.selectedStation(popstation)}
                      selected={
                        active === popstation.KioskId ||
                        stationId === popstation.KioskId
                      }
                    >
                      <TextItemDesc
                        selected={
                          active === popstation.KioskId ||
                          stationId === popstation.KioskId
                        }
                      >
                        <StyledHeading
                          selected={
                            active === popstation.KioskId ||
                            stationId === popstation.KioskId
                          }
                          level={5}
                        >
                          {popstation.POPStationName}
                        </StyledHeading>
                        <p>
                          {popstation.HouseBlockNumber} {popstation.StreetName},
                          Singapore {popstation.ZipCode}{' '}
                        </p>
                        <p>{popstation.Location}</p>
                      </TextItemDesc>
                    </TextItem>
                  </TextItemContainer>
                );
              })}
            {view === ITEM_VIEWS.LIST && increment !== popstations.length && (
              <Anchor onClick={() => this.paginateItem('add')}>
                Show more
              </Anchor>
            )}
          </Column>
        </MobileOnly>
        <Column sm={false} md={12} lg={12}>
          <ListWrapper>
            {increment - 5 > 0 && (
              <ArrowUp
                onClick={() => this.paginateItem('sub')}
                data-testid={'arrowup'}
              >
                <PageUp />
              </ArrowUp>
            )}
            <ListContainer>
              {popstations.map((popstation, i) => {
                return (
                  <TextItemContainer
                    key={i}
                    show={i < increment && i >= increment - 5}
                  >
                    <TextItem
                      block={true}
                      {...this.props}
                      onClick={() => this.selectedStation(popstation)}
                      selected={
                        active === popstation.KioskId ||
                        stationId === popstation.KioskId
                      }
                    >
                      <TextItemDesc
                        selected={
                          active === popstation.KioskId ||
                          stationId === popstation.KioskId
                        }
                      >
                        <StyledHeading
                          selected={
                            active === popstation.KioskId ||
                            stationId === popstation.KioskId
                          }
                          level={5}
                        >
                          {popstation.POPStationName}
                        </StyledHeading>
                        <StyledP>
                          {popstation.HouseBlockNumber} {popstation.StreetName},
                          Singapore {popstation.ZipCode}{' '}
                        </StyledP>
                        <StyledP>{popstation.Location}</StyledP>
                      </TextItemDesc>
                    </TextItem>
                  </TextItemContainer>
                );
              })}
            </ListContainer>
            {increment < popstations.length && (
              <ArrowDown
                onClick={() => this.paginateItem('add')}
                data-testid={'arrowdown'}
              >
                <PageDown />
              </ArrowDown>
            )}
          </ListWrapper>
        </Column>
      </>
    );
  }
}

ListDrawer.defaultProps = {
  popstations: [],
  isMobile: false,
  selectedStation: noop,
  activeFilter: noop,
  stationId: ''
};

ListDrawer.propTypes = {
  /** List of Popstations */
  popstations: PropTypes.array,
  /** Selected station data */
  selectedStation: PropTypes.func,
  /** StationId */
  stationId: PropTypes.string,
  /** Map */
  activeFilter: PropTypes.func
};

export default ListDrawer;
