import React from 'react';
import { Helmet } from 'react-helmet';
import InputDeliveryDetails from '../containers/InputDeliveryDetails';
import withLayout from '../components/withLayout';
import { ThemeProvider } from '@dls/web';

import { PRODUCT_NAME, DELIVERY_DETAILS } from '../constants/page_content.json';

const InputDeliveryDetailsPage = () => (
  <ThemeProvider brand="advance">
    <Helmet defaultTitle={PRODUCT_NAME} titleTemplate="%s">
      <title>{DELIVERY_DETAILS.TITLE}</title>
    </Helmet>
    <InputDeliveryDetails />
  </ThemeProvider>
);

export default withLayout(InputDeliveryDetailsPage);
